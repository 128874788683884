<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">จัดการคิวตรวจ</h4>
      </div>

      <div class="d-flex flex-column">
        <h3>เพิ่มรายการคิวตรวจ</h3>
        <v-row class="mt-2">
          <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-row align-center">
            <span class="label-drudsandmedical-add-stock-incard mr-3">ผู้เข้ารับการตรวจ</span>
            <v-select
              :items="itemsPerson"
              v-model="selectPerson"
              item-text="name"
              item-value="name"
              label="ผู้เข้ารับการตรวจ"
              placeholder="เลืือกผู้เข้ารับการตรวจ"
              no-data-text="ยังไม่มีข้อมูล"
              class="pt-0 mt-0"
              single-line
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-row align-center">
            <span class="label-drudsandmedical-add-stock-incard mr-3">ผู้ตรวจ</span>
            <v-select
              :items="itemsInspector"
              v-model="selectInspector"
              item-text="name"
              item-value="name"
              label="ผู้ตรวจ"
              placeholder="เลืือกผู้ตรวจ"
              no-data-text="ยังไม่มีข้อมูล"
              class="pt-0 mt-0"
              single-line
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-row align-center">
            <span class="label-drudsandmedical-add-stock-incard mr-3">ห้องตรวจ</span>
            <v-select
              :items="itemsExaminationRoom"
              v-model="selectExaminationRoom"
              item-text="name"
              item-value="name"
              label="ห้องตรวจ"
              placeholder="เลืือกห้องตรวจ"
              no-data-text="ยังไม่มีข้อมูล"
              class="pt-0 mt-0"
              single-line
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-row align-center">
            <span class="label-drudsandmedical-add-stock-incard mr-3">วันที่</span>
            <v-menu
              ref="menuSelectDate"
              v-model="menuSelectDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedSelectDateFormatted"
                  label="วัน/เดือน/ปี"
                  placeholder="ระบุวัน/เดือน/ปี"
                  append-outer-icon="mdi-calendar-month"
                  readonly
                  required
                  class="pt-0 mt-0"
                  single-line
                  hide-details
                  @click:append-outer="menuSelectDate = true"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectDate"
                no-title
                @change="menuSelectDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <div class="d-flex flex-row">
          <v-spacer/>
          <v-btn
            :loading="loaderAdd"
            :disabled="loaderAdd"
            color="success"
            class="my-3 white--text"
            @click="addNewQueue()"
          >
            <v-icon
              left
              dark
            >
              mdi-plus-circle
            </v-icon>
            เพิ่มคิวใช้บริการ
          </v-btn>
        </div>
      </div>

      <h3>รายการห้องตรวจ</h3>
      <v-row class="mt-2">
        <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-row align-center">
          <span class="label-drudsandmedical-add-stock-incard mr-3">เลือกวันที่</span>
          <v-menu
            ref="menuFilterDate"
            v-model="menuFilterDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedFilterDateFormatted"
                label="วัน/เดือน/ปี"
                placeholder="ระบุวัน/เดือน/ปี"
                append-outer-icon="mdi-calendar-month"
                readonly
                required
                class="pt-0 mt-0"
                single-line
                hide-details
                @click:append-outer="menuFilterDate = true"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filterData.date"
              no-title
              @change="menuFilterDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-row align-center">
          <span class="label-drudsandmedical-add-stock-incard mr-3">ผู้เข้ารับการตรวจ</span>
            <v-select
              :items="itemsPerson"
              v-model="filterData.person"
              item-text="name"
              item-value="name"
              label="ผู้เข้ารับการตรวจ"
              placeholder="เลืือกผู้เข้ารับการตรวจ"
              no-data-text="ยังไม่มีข้อมูล"
              class="pt-0 mt-0"
              single-line
              hide-details
            ></v-select>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-row align-center">
          <span class="label-drudsandmedical-add-stock-incard mr-3">ห้องตรวจ</span>
          <v-select
            :items="itemsExaminationRoom"
            v-model="filterData.examinationRoom"
            item-text="name"
            item-value="name"
            label="ห้องตรวจ"
            placeholder="เลืือกห้องตรวจ"
            no-data-text="ยังไม่มีข้อมูล"
            class="pt-0 mt-0"
            single-line
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="data"
        :loading="loaddata"
        loading-text="กำลังโหลด..."
      >
      <template v-slot:item.queue_date="{ item }">{{moment(item.queue_date).add(543, 'year').format('DD/MM/YYYY')}}</template>
      <template v-slot:item.queue_moredetail="{ item }">
        <a v-if="item.queue_moredetail" style="color: blue; text-decoration: underline;">ข้อมูลการใช้บริการ</a>
        <span v-else>ไม่มีข้อมูล</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          icon
          @click="editList(item)"
        >
          <v-icon>mdi-square-edit-outline</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="deleteList(item)"
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogEdit" max-width="500px">
      <v-card>
        <v-card-title>แก้ไขข้อมูลคิวใช้บริการ</v-card-title>
        <v-card-text>
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ประเภท:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7">
              <span>{{dialogData.queue_type}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">วันที่:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7">
              <span>{{moment(dialogData.queue_date).add(543, 'year').format('DD/MM/YYYY')}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ลูกค้า:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7">
              <span>{{dialogData.queue_customer}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ผู้ตรวจ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7">
              <span>{{dialogData.queue_doctor}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ห้องตรวจ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7">
              <span>{{dialogData.queue_room}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">สถานะ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7">
              <v-select
                :items="itemsStatus"
                v-model="dialogData.queue_status"
                item-text="name"
                item-value="name"
                label="สถานะ"
                placeholder="เลืือกสถานะ"
                no-data-text="ยังไม่มีข้อมูล"
                class="pt-0 mt-0"
                single-line
                hide-details
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeDialog">
            <v-icon class="mr-3">mdi-close-circle</v-icon>
            ยกเลิก
          </v-btn>
          <v-btn
            color="success"
            text
            @click="submitDialog"
          >
            <v-icon class="mr-3">mdi-content-save</v-icon>
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import swal from 'sweetalert'

export default {
  data () {
    return {
      loader: null,
      loaddata: false,
      loaderAdd: false,
      dialogEdit: false,
      dialogData: {},
      data: [
        {
          _id: '0001',
          no: 1,
          queue_type: 'ใช้บริการ',
          queue_date: moment(),
          queue_customer: 'คุณเอ',
          queue_doctor: 'หมอหนึ่ง',
          queue_room: 'ห้องตรวจ 1',
          queue_status: 'รอปรึกษา',
          queue_moredetail: ''
        }, {
          _id: '0002',
          no: 2,
          queue_type: 'ใช้บริการ',
          queue_date: moment(),
          queue_customer: 'คุณบี',
          queue_doctor: 'หมอสอง',
          queue_room: 'ห้องตรวจ 2',
          queue_status: 'รอตรวจ',
          queue_moredetail: ''
        }, {
          _id: '0003',
          no: 3,
          queue_type: 'ตรวจ',
          queue_date: moment(),
          queue_customer: 'คุณซี',
          queue_doctor: 'หมอหนึ่ง',
          queue_room: 'ห้องตรวจ 1',
          queue_status: 'รอตรวจ',
          queue_moredetail: ''
        }, {
          _id: '0004',
          no: 4,
          queue_type: 'ตรวจ',
          queue_date: moment(),
          queue_customer: 'คุณดี',
          queue_doctor: 'หมอสอง',
          queue_room: 'ห้องตรวจ 2',
          queue_status: 'ตรวจแล้ว',
          queue_moredetail: 'มี'
        }
      ],
      itemsPerson: [],
      itemsInspector: [],
      itemsExaminationRoom: [],
      itemsStatus: [{
        _id: '00000',
        name: 'รอปรึกษา',
        value: 'waiting for advice'
      }, {
        _id: '00001',
        name: 'รอตรวจ',
        value: 'waiting for examination'
      }, {
        _id: '00002',
        name: 'ตรวจแล้ว',
        value: 'complete'
      }],
      selectPerson: '',
      selectInspector: '',
      selectExaminationRoom: '',
      menuSelectDate: false,
      selectDate: new Date().toISOString().substr(0, 10),
      filterData: {
        date: new Date().toISOString().substr(0, 10),
        person: '',
        examinationRoom: ''
      },
      menuFilterDate: false
    }
  },
  methods: {
    initData () {
      const self = this
      self.loaddata = true
      setTimeout(() => {
        self.loaddata = false
      }, 2000)
    },
    clearDialogItem () {
      const self = this
      self.dialogData = {}
    },
    closeDialog () {
      const self = this
      self.dialogEdit = false
      self.clearDialogItem()
      self.initData()
    },
    gotosavedetail () {
      const self = this
      setTimeout(() => {
        self.$router.push({ name: 'CaseDetailScreen', params: { customerItems: self.dialogData } })
      }, 200)
    },
    addNewQueue () {
      const self = this
      self.loaderAdd = true
      swal('สำเร็จ', 'เพิ่มคิวใช้บริการสำเร็จ', 'success', {
        button: false,
        timer: 3000
      }).then(() => {
        self.loaderAdd = false
        self.initData()
      })
    },
    editList (item) {
      const self = this
      self.dialogData = item
      self.dialogEdit = true
    },
    submitDialog () {
      const self = this
      self.loaddata = true
      swal('สำเร็จ', 'แก้ไขคิวใช้บริการสำเร็จ', 'success', {
        button: false,
        timer: 3000
      }).then(() => {
        self.dialogEdit = false
        self.initData()
      })
    },
    deleteList (item) {
      const self = this
      self.loaddata = true
      var itemindex = null
      itemindex = self.data.findIndex(el => {
        return el._id === item._id
      })
      swal(`ต้องการลบรายการ "${item.queue_type}" คนไข้ "${item.queue_customer}" ในสถานะ "${item.queue_status}" ใช่หรือไม่`, {
        dangerMode: true,
        buttons: {
          cancel: 'ไม่ใช่',
          confirm: {
            text: 'ใช่',
            value: 'confirm'
          }
        }
      }).then((value) => {
        if (value === 'confirm') {
          swal('สำเร็จ', 'ลบข้อมูลสำเร็จ', 'success', {
            button: false,
            timer: 2000
          }).then(() => {
            self.data.splice(itemindex, 1)
            self.loaddata = false
          })
        } else {
          swal('คำเตือน', 'ยังไม่ได้ลบข้อมูล', 'warning', {
            button: false,
            timer: 3000
          })
          self.initData()
        }
      })
    }
  },
  computed: {
    headers () {
      return [
        {
          text: '#',
          align: 'center',
          value: 'no',
          sortable: false
        }, {
          text: 'ประเภท',
          align: 'center',
          value: 'queue_type',
          sortable: false
        }, {
          text: 'วันที่',
          align: 'center',
          value: 'queue_date',
          sortable: false
        }, {
          text: 'ลูกค้า (คนไข้)',
          align: 'center',
          value: 'queue_customer',
          sortable: false
        }, {
          text: 'หมอ (ผู้ตรวจ)',
          align: 'center',
          value: 'queue_doctor',
          sortable: false
        }, {
          text: 'ห้องตรวจ',
          align: 'center',
          value: 'queue_room',
          sortable: false
        }, {
          text: 'สถานะ',
          align: 'center',
          value: 'queue_status',
          sortable: false
        }, {
          text: 'ข้อมูลตรวจ/บริการ',
          align: 'center',
          value: 'queue_moredetail',
          sortable: false
        }, {
          text: 'จัดการ',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    },
    computedSelectDateFormatted () {
      const self = this
      return self.selectDate ? moment(self.selectDate).format('DD/MM/YYYY') : ''
    },
    computedFilterDateFormatted () {
      const self = this
      return self.filterData.date ? moment(self.filterData.date).format('DD/MM/YYYY') : ''
    }
  }
}
</script>
